<template>
    <b-form @submit.prevent="login()">
        <b-form-row>
            <TextControl
                :configurable="false"
                v-model="username"
                :label="$l('platon.login', 'Логин')"
                :placeholder="$l('platon.login', 'Логин')"
                autocomplete="username"
                class="mb-0"
                :class="{ invalid: $v.username.$dirty && !$v.username.required }"
            />

            <small class="err-text" v-if="$v.username.$dirty && !$v.username.required">
                {{ $l("platon.enterUsername", "Фойдаланувчи номини киритинг") }}
            </small>

            <PasswordControl
                v-model="password"
                :label="$l('platon.password', 'Парол')"
                :placeholder="$l('platon.password', 'Парол')"
                autocomplete="current-password"
                type="password"
                :configurable="false"
                class="mt-2 mb-0"
                :class="{ invalid: $v.password.$dirty && !$v.password.required }"
            />

            <small class="err-text" v-if="$v.password.$dirty && !$v.password.required">
                {{ $l("platon.enterPassword", "Паролни киритинг") }}
            </small>
            <small class="err-text" v-if="$v.password.$dirty && !$v.password.minLength">
                {{ $l("platon.password_min_length", "Парол 6та дан кам бўлмаслиги керак") }}
            </small>
        </b-form-row>
        <btn
            :disabled="$v.invalid"
            :loading="isLoggingIn"
            @click="login()"
            class="mt-3 btn-block"
            type="submit"
            variant="primary"
            >{{ $l("platon.enter", "Кириш") }}
        </btn>
    </b-form>
</template>

<script>
import PasswordControl from "@Platon/components/form/controls/PasswordControl.vue"
import TextControl from "@Platon/components/form/controls/TextControl.vue"
import Btn from "@Platon/components/extended/Btn.vue"
import { minLength, required } from "vuelidate/lib/validators"
import LoginPasswordProviderMixin from "@Platon/components/login/LoginPasswordProviderMixin"

export default {
    name: "LoginPasswordProvider",

    components: { PasswordControl, Btn, TextControl },

    mixins: [LoginPasswordProviderMixin],

    data() {
        return {
            username: "",
            password: ""
        }
    },

    validations: {
        username: { required },
        password: { required, minLength: minLength(6) }
    },

    methods: {
        login() {
            if (this.$v.$invalid) {
                this.$v.$touch()
                return
            }

            this.tryLogin(this.username, this.password)
        }
    }
}
</script>

<style scoped>
.err-text {
    color: red;
    padding-top: 4px;
    padding-left: 8px;
}
</style>
